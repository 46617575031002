import React from 'react'
import areasImg from '../../assets/reactjs2.png.svg'
import Goodimg from '../../assets/List → Item.png'

export default function CheckAreas() {

    const areasData = [
        {
            id: 1,
            title: "Transform your idea into product",
            text: "The right technology solutions enable a successful launch of an app, build trust among investors and customers. Create intuitive and useful product combining top front end development services."
        },
        {
            id: 2,
            title: "Transform your idea into product",
            text: "Win the interest and trust of new users. Introduce new features easily in line with your clients' needs and transform engagement into loyalty by solving real life problems."
        },
        {
            id: 3,
            title: "Transform your idea into product",
            text: "Build your app with the highest security standards and safeguard yourself from the loss of potential clients"
        },
        {
            id: 4,
            title: "Transform your idea into product",
            text: "Build a cross-platform application by leveraging the power of PWAs, and SPAs and providing a native experience to users on every device using React."
        },
        {
            id: 5,
            title: "Transform your idea into product",
            text: "Let expert react front-end developers take care of the speed of your product and provide a fast and hassle-free user experience"
        },
        {
            id: 6,
            title: "Transform your idea into product",
            text: "Choose the right front-end solution that will enable you to add new features seamlessly in the future, even in the changing market"
        },
    ]

    return (
        <>
            <div className='mt-12'>
                <div className='flex flex-col justify-center text-center items-center px-44 '>
                    <p className='text-[40px] text-[#DD2F29] font-bold'>
                        Check in which areas you can be <span className='text-[#2E3D62]'> successful in choosing the right frontend solutions </span>
                    </p>
                    <p className='text-[16px] text-[#554D63] font-medium'>
                        How to leverage the power of custom-made frontend development?
                    </p>
                </div>
                <div className='w-full flex'>
                    <div className='w-1/2 px-28 pt-9 space-y-3'>
                        {areasData.map((items) => (
                            <div className='space-y-2'>
                                <div className='flex items-center'>
                                    <img src={Goodimg} alt='img' className='h-[17px] w-[17px]' />
                                    <p className='text-[16px] text-[#6A6377] font-normal pl-2'>{items.title}</p>
                                </div>
                                <div className='text-[16px] text-[#554D63] font-medium'>
                                    <p>{items.text}</p>
                                </div>
                            </div>
                        ))}
                        <div>
                            <button className='bg-[#DD2F29] rounded-full w-[240px] h-[43px] text-[#FFFFFF] font-medium uppercase'>Talk to Our Experts</button>
                        </div>
                    </div>
                    <div className='w-1/2 flex justify-center items-center'>
                        <div>
                            <img src={areasImg} alt='AreasImage' className='h-[288px] w-[432]' />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
