import React from 'react'
import upwork_image from '../assets/images/upwork.png';


export default function Datacard() {

    const data =[
        {
            id:"1",
            number: "80+",
            color: "#CB0B0B",
            img:     false,
            title: "Global Clients",

        },
        {
            id:"2",
            number: "40+",
            color: "#234091",
            img:     false,
            title: "Number of Employees",
        },
        {
            id:"3",
            number: "100%",
            color: "#CB0B0B",
            img:     true,
            title: "Job Success in Upwork",
        },
    ]

  return (
    <>
    <div className=' flex flex-wrap justify-center items-center gap-2 px-4 lg:gap-6 rounded-lg'>
         {data.map((items)=>(
             <div key={items.id} className='bg-[#FFFFFF] w-full md:w-[30%] h-[142.39px] flex items-center justify-center flex-col shadow-md hover:shadow-lg hover:scale-105 transition-transform duration-500 ease-in-out'>
                <p className={`text-[${items.color}] font-medium text-2xl`}>{items.number}</p>
                {items.img && <img src={upwork_image} alt="upworkimg" className='h-7 w-7 pt-3 object-cover'/>}
                <p className='text-[#282728] font-light text-center text-base pt-3'>{items.title}</p>
            </div>
        ))}
    </div>
    </>
  )
}
