import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function PhoneNumber({
  value,
  placeholder,
  country,
  className,
  onChange,
  required,
  onPhoneCodeChange, // Add a callback for phone code change
}) {
  const handlePhoneChange = (
    phone,
    country,
    e,
    formattedValue
  ) => {
    // Call the provided onChange callback
    onChange && onChange(phone, country, e, formattedValue);

    // Extract phone code and call the onPhoneCodeChange callback
    const phoneCode = country.dialCode;
    onPhoneCodeChange && onPhoneCodeChange(phoneCode);
  };
  return (
    <PhoneInput
      country={country}
      enableSearch
      enableLongNumbers
      value={value}
      onChange={handlePhoneChange}
      placeholder={placeholder}
      inputProps={{
        required: required,
        className: className,
      }}
    />
  );
}

export default PhoneNumber;
