import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './pages/Header';
import Partners from './components/Partners/Partners';
import WhyHNLTech from './components/whyHNLTech';
import OurTechnologies from './components/OurTechnologies';
import Footer from './components/Footer/Footer';
// import SwiperR from './components/swiper/SwiperR';
import ServiceWeOffer from './components/ServiceWeOffer/Index';
import rimg from './assets/image 55.svg'
import ScheduleSession from './components/ScheduleSession/ScheduleSession'
import profile from './assets/Ellipse 89.svg'
import headerbg from './assets/images/headerbg.jpg'
import aboutbg from './assets/images/aboutusbg.jpg'
import techbg from './assets/techbg.svg'
import contactbg from './assets/images/contactbg.jpg'
import laptop from './assets/laptop.png'
import RemoteDevelopers from './components/RemoteDevelopers/RemoteDevelopers';
import TestimonialCard from './components/Card/TestimonialCard';
import HeaderSection from './components/Navbar/HeaderSection';
import ReactJsServices from './components/ReactJsServices';
import Reveiws from './components/Reveiws/Reveiws';
import ScrollToTop from './components/ScrollToTop';
import Projects from './components/Projects/Projects';
import PrefferedTechnology from './components/PrefferedTechnology/PrefferedTechnology';
import DigitalWorldTechnology from './components/DigitalWorldTechnology/DigitalWorldTechnology';
import WhyForServices from './components/WhyForServices/WhyForServices';
import FAQTechnology from './components/FAQTechnology/FAQTechnology';
import GrowTechnology from './components/GrowTechnology/GrowTechnology';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OurServicebg from './assets/images/projectbg.jpg'
import CheckAreas from './components/CheckingAreas/CheckAreas';
import KipsVirtual from './components/KipsVirtual/KipsVirtual';
import EmAiProject from './components/AppProjects/EmAiProject';
import MobileSection1 from './components/Mobile/MobileSection1'
import Mobilesection from './components/Mobile/Mobilesection'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ToastContainer autoClose={2000} />
      <Header />
      <Routes>

        <Route
          path='/'
          element={
            <>
              <HeaderSection title="HNL-Tech Delivers" desc="Innovative Software Solutions Tailored to Your Business Needs"
                profiletitle="Talha" profiledesc="Delivering cutting-edge solutions across web development, mobile apps, and blockchain, harnessing the latest in technology for your success"
                profilepic={profile} bookacall="Book a Call"
                bgimage={headerbg}
                rightsideimage={rimg} mobileimg={laptop}

              />

              <Partners />
              <ServiceWeOffer />
              <OurTechnologies />
              <WhyHNLTech />
              <RemoteDevelopers />
              {/* <TestimonialCard /> */}
              <ScheduleSession />

            </>
          }
        />
        <Route
          path='/ourprojects'
          element={
            <>
              <HeaderSection title="Our Projects" desc="Innovative Software Solutions Tailored to Your Business Needs"
                profiletitle="Talha" profiledesc="Delivering cutting-edge solutions across web development, mobile apps, and blockchain, harnessing the latest in technology for your success"
                profilepic={profile} bookacall="Book a Call"
                bgimage={OurServicebg}
              // rightsideimage={rimg} 
              // mobileimg={laptop}

              />

              {/* <Partners /> */}
              {/* <ServiceWeOffer /> */}
              {/* <OurTechnologies /> */}
              {/* <WhyHNLTech /> */}
              <MobileSection1 />
              <EmAiProject />
              <Mobilesection />
              <RemoteDevelopers />


              <ScheduleSession />

            </>
          }
        />
        <Route
          path='/about'
          element={
            <>
              <HeaderSection title="About Us" desc="Innovative Software Solutions Tailored to Your Business Needs"
                profiletitle="Talha" profiledesc="Delivering cutting-edge solutions across web development, mobile apps, and blockchain, harnessing the latest in technology for your success"
                profilepic={profile} bgimage={aboutbg} bookacall="Book a Call"
              />
              <WhyHNLTech />
              {/* <Reveiws /> */}
              <RemoteDevelopers />
              <ScheduleSession />
            </>
          }
        />
        <Route
          path='/contact'
          element={
            <>
            
              <HeaderSection
              //  title="Contact Us" desc="Innovative Software Solutions Tailored to Your Business Needs"
                // profiletitle="Talha" profiledesc="Delivering cutting-edge solutions across web development, mobile apps, and blockchain, harnessing the latest in technology for your success"
                // profilepic={profile} 
                bgimage={contactbg}
              />
              {/* <RemoteDevelopers /> */}
              {/* <Reveiws /> */}
              <ScheduleSession />
            </>
          }
        />
        <Route
          path='/services'
          element={
            <>
              <HeaderSection title="Our Services" desc="Innovative Software Solutions Tailored to Your Business Needs"
                profiletitle="Talha" profiledesc="Delivering cutting-edge solutions across web development, mobile apps, and blockchain, harnessing the latest in technology for your success"
                profilepic={profile} bgimage={aboutbg} bookacall="Book a Call"
              />
              <Partners />
              <ServiceWeOffer />
              <OurTechnologies />
              <RemoteDevelopers />
              {/* <TestimonialCard /> */}
              <ScheduleSession />
            </>
          }
        />
        <Route
          path='/technologeis'
          element={
            <>
              <HeaderSection title="Streamline Your Business App Using ReactJS Development Services" desc="Build high-performing web applications and hybrid mobile applications with the help of technology created by Facebook."
                profiletitle="Talha" profiledesc="Delivering cutting-edge solutions across web development, mobile apps, and blockchain, harnessing the latest in technology for your success"
                profilepic={profile} bookacall="Book a Call"
                bgGradient={true}
                techimg={techbg}
              // bgimage={headerbg} 
              // rightsideimage={rimg} mobileimg={laptop}
              />
              <Partners />
              <GrowTechnology />
              <CheckAreas />
              <ReactJsServices />
              <Projects />
              <DigitalWorldTechnology />
              <Reveiws />
              <PrefferedTechnology />
              <WhyForServices />
              <FAQTechnology />
              {/* <TestimonialCard /> */}
              <ScheduleSession />
            </>
          }
        />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
