import React from 'react'
import circle from '../../assets/circle.svg'
import micro from '../../assets/Vector.svg'
import img1 from '../../assets/customsoftware.svg'
import img2 from '../../assets/web.svg'
import img3 from '../../assets/crossplt.svg'
import img4 from '../../assets/mobile.svg'
import img5 from '../../assets/ecoomerce.svg'
import img6 from '../../assets/UIU.svg'
import img7 from '../../assets/digital.svg'
import img8 from '../../assets/contactt.webp.svg'
import '../ServiceWeOffer/servicestyle.css'
import { NavLink } from 'react-router-dom'

export default function ServiceCard() {

    const card = [
        {
            id: "1",
            img: img1,
            title: "Custom Software Development",
            paragraph: "We specialize in custom software development, tailoring solutions to meet your unique business needs. Let us bring your ideas to life with our expertise and innovation",
            footer: "Read More >"
        },
        {
            id: "2",
            img: img2,
            title: "Web Application development",
            paragraph: "We excel in crafting dynamic web applications that elevate your online presence and streamline business operations. Trust us to deliver high-quality solutions tailored to your specific requirements",
            footer: "Read More >"
        },
        {
            id: "3",
            img: img3,
            title: "Cross-Platform Developmemt",
            paragraph: "We specialize in crafting bespoke platform solutions, tailored precisely to your business goals and requirements. Elevate your operations with our custom platform development expertise",
            footer: "Read More >"
        },
        {
            id: "4",
            img: img4,
            title: "Mobile App Development",
            paragraph: "Innovative, secure, and dependable mobile applications for smooth user experiences.",
            footer: "Read More >"
        },
        {
            id: "5",
            img: img5,
            title: "Online Store Creation",
            paragraph: "Premier e-commerce application development firm offering tailored e-commerce mobile apps and solutions for your business at an affordable cost.",
            footer: "Read More >"
        },
        {
            id: "6",
            img: img6,
            title: "UI/UX Desgin",
            paragraph: "We craft intuitive, captivating, and easy-to-use interfaces to enhance digital experiences and boost brand value.",
            footer: "Read More >"
        },
        {
            id: "7",
            img: img7,
            title: "Online Marketing Solutions",
            paragraph: "Digital marketing aims to engage a specific audience, elevate brand recognition, generate leads, increase web traffic, and ultimately, convert leads into customers.",
            footer: "Read More >"
        },
        // {
        //     id: "8",
        //     img: img8,
        //     title: "Custom Software Development",
        //     paragraph: "Custom software development services for startups, small-to- midsize (SMB), and enterprise- size businesses.",
        //     footer: "Read More >"
        // },

    ]
    return (
        <div className='flex flex-wrap gap-6 xl:gap-8=6 py-12 items-center justify-center  mx-[15px] xl:mx-24 pb'>
            {card.map((items) => (
                <div key={items.id} className='bg-[#FFFFFF] flex flex-col flipimg h-[400px]  w-[268px] gap-2 p-5 shadow-lg hover:shadow-2xl hover:scale-105 transition-transform duration-300 ease-in-out pt-6 rounded-lg'>
                    <div className='h-20 w-20 flex justify-start items-center relative'>
                        <img src={items.img} alt="pic" className='h-16 w-16 hover:text-white '
                        //  style={{
                        //     backgroundImage: `url(${circle})`,
                        //     backgroundSize: 'contain', 
                        //     backgroundPosition: 'center', 
                        //     backgroundRepeat:"no-repeat"
                        //     // height: '100vh',
                        //   }}
                        />
                        <img src={circle} alt="" className='absolute top-0 right-0' />
                    </div>
                    <p className={`text-[17px] text-[#332F3B] font-medium title`}>{items.title}</p>
                    <p className='text-[16px] text-[#282728] font-light'>{items.paragraph}</p>
                    {/* <a href=""></a> */}
                    {/* <a href='' className='text-[#DD2F29]   font-semibold text-[16px] readmore pt-14'>{items.footer}</a> */}
                </div>
            ))}
            <div className='bg-[#DD2F29] flex flex-col flipimg h-[400px]  w-[268px] gap-2 p-5 shadow-lg hover:shadow-2xl hover:scale-105 transition-transform duration-300 ease-in-out pt-6 rounded-lg'>
                <img src={micro} alt="pic" className='h-16 w-16 hover:text-white ' />
                {/* <p className={`text-[17px] font-medium title`}>items.title</p> */}
                <p className='text-[16px] text-[#FFFFFF] font-light GTouch'>Are you interested in learning more about our Custom Software, Web or Mobile Application Development, and Digital Marketing Services?</p>
                {/* <a href=""></a> */}
                <NavLink to="/contact" className='text-[#FFFFFF]   font-semibold text-[16px] capitalize  readmore1 pt-4'>
                Contact us &#62;
                </NavLink>
                {/* <a href='/contact' className='text-[#FFFFFF]   font-semibold text-[16px] capitalize  readmore1 pt-4'>get in touch &#62; </a> */}
            </div>
            {/* {card.slice(4).map((items) => (
                <div key={items.id} className='bg-[#FFFFFF] flex flex-col h-[400px] w-[268px] gap-2 p-2 shadow-lg hover:shadow-2xl hover:scale-105 transition-transform duration-300 ease-in-out pt-6 rounded-lg'>
                    <img src={items.img} alt="pic" className='h-16 w-16' />
                    <p className='text-[17px] font-medium'>{items.title}</p>
                    <p className='text-[16px] font-light'>{items.paragraph}</p>
                    <p className='text-[#DD2F29] font-semibold text-[16px] pt-24'>{items.footer}</p>
                </div>
            ))} */}
        </div>
    )
}
