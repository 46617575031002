import React from 'react'
import CasestudyImg from '../../assets/AI-based-Digital-client.png.svg'
import ClientImg from '../../assets/vechileclient.png.svg'

export default function Projects() {
    const projectdata = [
        {
            id: 1,
            img: ClientImg,
            title: "How TechAvidus reduced operations Team's efforts for Car delivery company in Canada.",
            text: ["The project's primary goal was to reduce the operations team's involvement in delivery of cars. This would also result in reducing the care costs related to those requests.", "Other challenges were unclear delivery process and lack of real time tracking in system.", "TechAvidus developed web application for car delivery company's staff and car delivery mobile application for Client and Drivers.", "The result was decreasing the number of hours Staff and driver spend on the manual update process over phone calls, thus, increasing their time spent driving, transparency and serving customers."],
            btnText: "HIRE DEVELOPERS",
            direction: false,
        },
        {
            id: 2,
            img: CasestudyImg,
            title: "We solved problems in Aviation Asset Management by developing Peer to Peer collaboration platform.",
            text: ["Plan Aero transform fragmented aviation data into frictionless digital asset with blockchain technology. ", "TechAvidus designed frameworks to eliminate repetitive data reconciliation, transfer errors, and empower standardization and process improvement initiatives. We created digital twin of physical assets and created digital passports for every stockholder of aviation industry including airports, Aviation data providers, manufacturer and others.", "Supported 50+ document types to cover commercial data, compliance data, design data, logistic data, and maintenance data. Designed component to host the external APIs that customers can use to integrate with their existing systems."],
            btnText: "READ THE CASE STUDY",
            direction: true,
        }
    ]
    return (
        <>
            {projectdata.map((items) => (
                <div className={`md:flex md:w-full gap-8 xl:gap-0 xl:px-28 xl:mt-12 ${items.direction ? 'flex-row-reverse' : ''}`}>
                    <div className='md:mt-2 flex justify-center items-start md:w-1/2'>
                        <img src={items.img} alt='Vechileclient' className='h-[338.92px] w-[556.19px]  m-1 rounded-sm' />
                    </div>
                    <div className='md:w-1/2 space-y-5 m-1 md:mt-11 lg:mt-0'>
                        <div className='text-[#625D6A] md:text-sm lg:text-[22px] xl:text-[30px] font-semibold lg:leading-8'>
                            {items.title}
                        </div>
                        <div className='space-y-3 m-1 md:text-[12px] lg:text-[15px] xl:text-[15px] font-medium'>
                            {items.text.map((textitems) => (
                                <p> {textitems} </p>
                            ))}
                            <div className='m-1'>
                                <button className='bg-[#DD2F29] rounded-full w-[240px] h-[43px]'>
                                    <p className='text-[#FFFFFF] font-medium text-[12px] xl:text-[14px] uppercase'>{items.btnText}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}
