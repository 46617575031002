import React from 'react'
import { FcGoogle } from "react-icons/fc";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
import ReveiwCircle from '../ReveiwCircle';

export default function Reveiws() {
    return (
        <div className='my-11 lg:my-16'>
            <div className='px-9 md:px-32'>
                <h1 className="text-center font-[700] text-[24px] text-[#DD2F29]">150+ Five Star Reviews
                    <span className="text-center pl-2 font-[700] text-[24px] text-[#2E3D62]">
                        on Google, GoodFirms, Clutch & Upwork</span></h1>
            </div>
            <div className="flex md:flex-col md:items-center justify-center  mt-14">
                <div className="space-y-[95px] md:space-y-0 md:-ml-28 lg:-ml-36 xl:-ml-0  md:flex md:gap-28 lg:gap-36  xl:gap-48 md:items-center md:justify-center md:w-full">
                    <ReveiwCircle />
                    <ReveiwCircle />
                    <ReveiwCircle />
                </div>
                <div className="space-y-[95px] md:space-y-0 pt-32 md:pt-0 md:gap-28 md:pl-24 lg:pl-32 md:-mt-7 lg:gap-36 lg:-mt-9 xl:gap-48 xl:pl-[295px] xl:-mt-10  md:flex md:justify-center md:items-center  md:w-full">
                    <ReveiwCircle />
                    <ReveiwCircle />
                    <ReveiwCircle />
                </div>
            </div>

        </div>
    )
}
