import React from 'react';
import Navbar from '../components/Navbar/Navbar';

export default function Header() {
  return (
    <div className=""
      // style={{
      //   backgroundImage: `url(${headerbg})`,
      //   backgroundSize: 'cover', 
      //   backgroundPosition: 'center', 
      //   backgroundRepeat:"no-repeat"
      //   // height: '100vh',
      // }}
    >
      <Navbar />
      {/* <HeaderSection/> */}

    </div>
  );
}
