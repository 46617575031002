import React from 'react'
import ServicesWeOffer from './ServicesWeOffer'

export default function ReactJsServices() {
    return (
        <div>
            <ServicesWeOffer />
        </div>
    )
}
