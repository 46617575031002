import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import hamburger from '../../assets/SVG/ham.svg'
import "./navbar.css"
import { IoMdClose } from 'react-icons/io';

const tabs = [
    { name: 'Home', path: '/' },
    { name: 'Services', path: '/services' },
    { name: 'Our Projects', path: '/ourprojects' },
    // { name: 'Technologies', path: '/technologeis' },
    { name: 'Aboutus', path: '/about' },
    { name: 'Contactus', path: '/contact' }
];

export default function Navbar() {
    const [isSticky, setIsSticky] = useState(false);

    const [sideNav, setSideNav] = useState(false)
    const [isClosing, setIsClosing] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            // Set a specific scroll threshold to add the sticky effect (e.g., 50px)
            if (window.scrollY > 50) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Add the event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleToggle = () => {
        if (sideNav) {
            setIsClosing(true);
            setTimeout(() => {
                setSideNav(false);
                setIsClosing(false);
            }, 600); // Make sure this matches your CSS animation duration
        } else {
            setSideNav(true);
        }
    };


    return (
        <div>
            <div className={`flex  w-full justify-between px-6 lg:px-0 lg:justify-around items-center pt-3 bg-transparent z-50 ${isSticky && !sideNav ? 'sticky-navbar' : ''}`}>
                <div className='flex justify-start items-center cursor-pointer pb-4 gap-3    '>
                    <img src={logo} alt="logo" className='h-[47px] logo-spin lg:h-[57px]   w-auto' />
                    <div>
                        <h1 className='text-[#FF0000] text-[39px] lg:text-[50px] leading-none font-extrabold'>HNL</h1>
                        <p className={` ${isSticky ? "text-black" : "text-white"} font-[700] text-[12px] lg:text-[16px] leading-none `}>Tech-Solutions</p>
                    </div>
                </div>
                <div className='hidden lg:flex lg:justify-center lg:items-center'>
                    <ul className='uppercase flex   lg:gap-[25px] xl:gap-[45px]'>
                        {tabs.map((tab) => (
                            <li key={tab.name} className={`font-[600] ${isSticky ? "text-black" : "text-white"}  hover:text-[#9B0303] cursor-pointer flex`}>
                                <NavLink
                                    to={tab.path}
                                    className={({ isActive }) =>
                                        isActive ? 'border-b-[6px] text-[#9B0303] border-[#9B0303]' : ''
                                    }
                                >
                                    {tab.name}
                                </NavLink>
                            </li>
                        ))}
                        {/* <li className='flex justify-center items-center'>
                            <button className='capitalize flex justify-center items-center border border-red-500 rounded-[50px] text-red-700 w-[142px] xl:w-[162px] h-[43px]'>
                                book a call
                            </button>
                        </li> */}
                    </ul>
                </div>
                <div onClick={handleToggle} className="lg:hidden cursor-pointer">
                    <img src={hamburger} alt="ham" />
                </div>
            </div>
            {sideNav &&
                <div className={`fixed inset-0 bg-black bg-opacity-60 z-40`} onClick={handleToggle}>
                    <div className={`fixed  max-w-xs w-5/6 md:w-2/5 top-0 bg-white lg:hidden ${isClosing ? 'fade-out-left' : 'fade-in-left'} border-r-4 border-[#FF0000] h-screen z-50`}>
                        <div className='pt-10 flex justify-center items-center cursor-pointer pb-4 gap-3    '>
                            <img src={logo} alt="logo" className='h-[47px] lg:h-[57px]   w-auto' />
                            <div>
                                <h1 className='text-[#FF0000] text-[39px] lg:text-[49px] leading-none font-extrabold'>HNL</h1>
                                <p className='text-black font-[700] text-[12px] lg:text-[18px] leading-none'>Technology</p>
                            </div>
                        </div>
                        <nav>
                            <ul className='capitalize flex flex-col items-center justify-center text-black lg:gap-[25px] xl:gap-[45px]'>
                                {tabs.map((tab) => (
                                    <li key={tab.name} className='font-[600] pt-[17px] cursor-pointer flex'>
                                        <NavLink
                                            to={tab.path}
                                            className={({ isActive }) =>
                                                isActive ? 'border-b-[3px] border-[#FF0000]' : ''
                                            }
                                        >
                                            {tab.name}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                            <div className="absolute -right-5 top-6 text-white px-2 py-2 rounded-full bg-[#283641] " onClick={handleToggle}>
                                <IoMdClose size={20} />
                            </div>
                        </nav>
                    </div>
                </div>
            }
        </div>
    );
}
