import React from 'react'
import Heading from './Heading'
import ServiceCard from './ServiceCard'

export default function ServiceWeOffer() {
    return (
        <div className='md:bg-[#FFF0F0]'>
            <Heading />
            <ServiceCard />
        </div>
    );
}
