import React from 'react'
import EmbraceImg from '../../assets/Embrace.svg'
import KeyboardImg from '../../assets/newKeyboardlogo.svg'
import EmbraceSlide from '../../assets/images/EmbraceSlide.png'
import KeyboardSlide from '../../assets/images/KeyboardSlide.png'
import RedbgImg from '../../assets/RedBg.svg'
import PurplebgImg from '../../assets/PurpleBg.svg'

export default function EmAiProject() {
    const projectdata = [
        {
            id: 1,
            logoimg: EmbraceImg,
            img: EmbraceSlide,
            title: "Embrace Period Tracker App",
            text: ["Track your period, understand how your body changes throughout the menstrual cycle, and get access to exclusive shopping offers. Join the Embrace community now!", "Safety starts with understanding how developers collect and share your data. Data privacy and security practices may vary based on your use, region and age The developer provided this information and may update it over time."],
            direction: false,
            bgImg: RedbgImg,
        },
        {
            id: 2,
            logoimg: KeyboardImg,
            img: KeyboardSlide,
            title: "AI Keyboard create your keyboard",
            text: ["TypeAI revolutionizes typing by integrating AI, offering instant access to ChatGPT, customizable prompts, and a vast prompt library, enhancing creativity and efficiency at your fingertips."],
            direction: true,
            bgImg: PurplebgImg,
        }
    ]
    return (
        <>
            {projectdata.map((projectItem) => (
                <div className='' style={{ backgroundImage: `url(${projectItem.bgImg})` }}>
                    <div className={`w-full flex justify-center items-center ${projectItem.direction ? 'flex-row-reverse z-30' : ''}`}>
                        <div className='w-1/2 h-[600px] z-50 p-14'>
                            <img src={projectItem.img} alt='slideImg' />
                        </div>
                        <div className='w-1/2 px-10 space-y-2'>
                            <img src={projectItem.logoimg} alt='logoImg' className='object-contain w-[140px] h-[130px]' />
                            <p className='text-[44px] font-[700] text-[#FFFFFF] uppercase w-[70%]'>{projectItem.title}</p>
                            {projectItem.text.map((textItem, index) => (
                                <p key={index} className='text-[#FFFFFF]'> {textItem} </p>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}