import React from 'react'
import google_image from '../assets/images/google.png';
import vector_image from '../assets/images/Vector.png';
import clutch_image from '../assets/images/clutch.png';
import fullStar from '../assets/SVG/star-yellow.png.svg'
import halfStar from '../assets/SVG/half-star-yellow.png.svg'
export default function Logocards() {

    const logodata = [
        {
            id: "1",
            img: google_image,
        },
        {
            id: "1",
            img: vector_image,
        },
        {
            id: "1",
            img: clutch_image,
        },
    ]

    return (
        <div className=' pt-4 flex flex-wrap justify-center items-center gap-2  px-4  lg:gap-6 rounded-lg'>
            {logodata.map((items) => (
                <div key={items.id} className='bg-[#FFFFFF] w-full md:w-[30%] h-[142.39px] flex items-center justify-center flex-col shadow-md hover:shadow-lg hover:scale-105 transition-transform duration-500 ease-in-out'>
                    <img src={items.img} alt="logoimages" className='h-[60px] w-[60px]' />
                    <Stars />
                </div>
            ))}
        </div>
    )
}

const Stars = () => {
    return (
        <div className='flex items-center justify-center gap-1 pt-2'>
            <img src={fullStar} alt="star" />
            <img src={fullStar} alt="star" />
            <img src={fullStar} alt="star" />
            <img src={fullStar} alt="star" />
            <img src={fullStar} alt="star" />
            {/* <img src={halfStar} alt="star" /> */}
        </div>
    )
}
