import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick-theme.css";
import matei from '../../assets/matei.svg'
// import p1 from '../../assets/par1.svg'
// import p2 from '../../assets/par2.svg'
// import p3 from '../../assets/par3.svg'
// import p4 from '../../assets/par4.svg'
import p1 from '../../assets/partners/eco.png'
import p2 from '../../assets/partners/12-ovigogzr7r2bx8oels7riyddjg7iydn2qedjn01ogy.jpg'
import p3 from '../../assets/partners/jazz.png'
import p4 from '../../assets/partners/nokia.png'
import p5 from '../../assets/partners/ptcl.jpg'
import p6 from '../../assets/partners/ufone.jpg'
import p7 from '../../assets/partners/zong.png'
import p8 from '../../assets/partners/18-ovigogzr7r2bx8oels7riyddjg7iydn2qedjn01ogy.jpg'
import p9 from '../../assets/partners/9-1-ovigogzr7r2bx8oels7riyddjg7iydn2qedjn01ogy.jpg'
import star from '../../assets/star.png.svg'
import newlogo from '../../assets/eshopbox-hover-removebg-preview.png'
import hstar from '../../assets/half-star.png.svg'
import { FcGoogle } from "react-icons/fc";
import '../Partners/partner.css'
// import { Carousel } from 'antd';

export default function Partners() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1000, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  return (
    <div>
      <div className='mt-24 overflow-hidden'>
        <Carousel
          arrows={false}
          autoPlay={true}
          swipeable={true}
          draggable={false}
          infinite={true}
          responsive={responsive}>
          <div><img src={p1} alt="" className="bw-filter w-[250px] h-[65px] object-contain" /></div>
          <div><img src={p2} alt="" className="bw-filter w-full h-[65px] object-contain" /></div>
          <div><img src={p3} alt="" className="bw-filter w-full h-[65px] object-contain" /></div>
          <div><img src={p4} alt="" className="bw-filter w-full h-[65px] object-contain" /></div>
          <div><img src={p5} alt="" className="bw-filter w-full h-[65px] object-contain" /></div>
          <div><img src={p6} alt="" className="bw-filter w-full h-[65px] object-contain" /></div>
          <div><img src={p7} alt="" className="bw-filter w-full h-[65px] object-contain" /></div>
          <div><img src={p8} alt="" className="bw-filter w-full h-[65px] object-contain" /></div>
          <div><img src={p9} alt="" className="bw-filter w-full h-[65px] object-contain" /></div>


        </Carousel>


        <div className="bg-[#FAFAFA] md:flex justify-center items-center md:h-[57px] mt-12">
          <div className='flex justify-center items-center gap-1 pt-4 md:pt-0'>

            <p className="text-[16px] font-[600] responsive-text leading-[20px] text-[#6A6377]">Our Customer Says<span className="text-[16px] responsive-text pl-0.5 font-[600] leading-[24px] text-[#234091]">Excellent</span></p>

            <div className='flex px-0 md:px-2'>
              <img src={star} alt="" className='w-5 h-5 md:w-[25px] md:h-[25px]' />
              <img src={star} alt="" className='w-5 h-5 md:w-[25px] md:h-[25px]' />
              <img src={star} alt="" className='w-5 h-5 md:w-[25px] md:h-[25px]' />
              <img src={star} alt="" className='w-5 h-5 md:w-[25px] md:h-[25px]' />
              <img src={hstar} alt="" className='w-5 h-5 md:w-[25px] md:h-[25px]' />
            </div>
          </div>
          <div>
            <p className="text-[16px] font-[600] flex justify-center items-center leading-[24px] pt-2 md:pt-0 md:pb-0 pb-5 text-[#6A6377]">4.7 out of 5 based on <span className="text-[16px] px-2 font-[600] flex justify-center items-center  leading-[24px] text-[#234091]"><FcGoogle /><span className='px-1.5'>reviews</span></span></p>
          </div>
        </div>
      </div>
    </div>
  )
}
