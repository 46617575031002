import React from 'react'
import Datacard from '../Datacard'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import Logocards from '../Logocards';
import emp from '../../assets/images/emppic.png'
import empbg from '../../assets/empbg.svg'
import '../whyHNLTech/WNot.css'
import "react-multi-carousel/lib/styles.css";


export default function Techcard() {
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 700, // You had this twice; keep it once
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover:true,
        autoplay: true,
        autoplaySpeed: 5000, // Adjust this to control the speed of autoplay, 2000 for 2 seconds
        cssEase: "linear",
        pauseOnHover: false,
        swipe: true,         // Explicitly enable swipe
        swipeToSlide: true, 
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>
            {/* <Title /> */}
            <div className='lg:flex justify-center items-center pt-14 lg:pt-4 overflow-hidden w-full pb-5 lg:pb-0'>
                <div className='lg:w-[50%] '
                    style={{
                        backgroundImage: `url(${empbg})`,
                        backgroundSize: 'contain',
                        // backgroundPosition: 'center',
                        backgroundRepeat: "no-repeat",
                        // Ensure the div has a set height if the carousel does not cover the entire view height
                        // height: '100vh', // Adjust or uncomment this as needed
                    }}
                >
                    {/* <Carousel */}
                    {/* // className='cursor-pointer'
                        // Carousel-specific styles removed since the background is on the container
                        // swipeable={true}
                        // draggable={true}
                        // customTransition="all .5"
                        // transitionDuration={500}
                        // arrows={false}
                        // swipeable={true}
                        // infinite={true}
                        // responsive={responsive}
                        // autoPlay={true}
                    // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    // autoPlaySpeed={1000}
                    // Additional carousel settings...
                    // > */}
                    {/* <img src={emp} alt="" /> */}
                    {/* <img src={emp} alt="" /> */}
                    {/* <img src={emp} alt="" /> */}
                    {/* <img src={emp} alt="" /> */}
                    {/* <img src={employeePic1} alt="" style={{ opacity: 0.9 }} /> */}
                    {/* More images or content */}
                    {/* </Carousel> */}
                    <Slider {...sliderSettings} className="">
                        <div className="focus:outline-none cursor-grab "><img src={emp} alt="" /></div>
                        {/* <div><img src={employeePic1} alt="" /></div> */}
                        <div className="focus:outline-none cursor-grab "><img src={emp} alt="" /></div>
                    </Slider>

                </div>

                <div className='lg:w-[50%] pt-8 md:pt-9'>

                    <div>
                        <div className=' justify-center flex items-center lg:hidden'>
                            <h1 className='text-[#2E3D62] text-[30px] md:text-[40px] font-bold'> Why <span className='text-[#CB0B0B]'> HNL-TECH?</span></h1>
                        </div>
                        <div className='pt-3 md:pt-0' >
                            <p className='text-[#554D63] text-[18px] text-center px-[25px] font-medium '>
                                At HNL-TECH-SOLUTIONS, we pride ourselves on our unwavering commitment to excellence, innovation, and client satisfaction. Our core values shape our culture and set us apart as a leader in the industry.
                            </p>
                            <p className='text-[#554D63] text-[18px] text-center px-[25px]  font-medium pt-5'>
                                150+ customers have rated <span className='text-[#CB0B0B]'> Five Star to  </span> <span className='text-[#CB0B0B] font-[700]'> HNL Tech-Solutions  </span>  on Google, GoodFirm, Clutch and Upwork.
                            </p>
                            <p className='text-[#CB0B0B] text-[18px] text-start px-[25px] hidden lg:block font-normal lg:font-[600] hover:underline cursor-pointer text-lg pt-7'>Learn More &rarr;</p>
                        </div>
                        <Datacard />
                        <Logocards />
                    </div>
                </div>
            </div>
        </>
    )
}
