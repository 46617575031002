import React from 'react'
import '../ReactJsServices/Circle.css'
export default function Circle() {
    const data = [
        {
            id: "1",
            paragraph: "Custom React.JS App Development",
        },
        {
            id: "2",
            paragraph: "Custom React.JS App Development",
        },
        {
            id: "3",
            paragraph: "Custom React.JS App Development",
        },
        {
            id: "4",
            paragraph: "Custom React.JS App Development",
        },
        {
            id: "5",
            paragraph: "Custom React.JS App Development",
        },
        {
            id: "6",
            paragraph: "Custom React.JS App Development",
        },
        {
            id: "7",
            paragraph: "Custom React.JS App Development",
        },
        {
            id: "8",
            paragraph: "Custom React.JS App Development",
        },
    ]
    return (
        <div className='flex flex-wrap justify-center items-center mt-2 ml-2'>
            {data.map((item) => (
                <div className='flex flex-wrap justify-start items-center mt-2 ml-2 bg-[#C24545] bg-opacity-[18%] rounded-3xl h-[55px] w-auto md:max-w-lg lg:w-[380px] xl:w-[586px]'>
                    <div key={item.id} className='flex justify-center items-center'>
                        <div className='bg-[#DD2F29] widthhieght rounded-[50%] h-[48px] w-[52px] flex justify-center items-center'>
                            <div className='border-[4px] md:border-[6px] border-white rounded-[50%] w-4 h-4' />
                        </div>
                        <p className='text-[16px] md:pl-1  text-black'>{item.paragraph}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}
