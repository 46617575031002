import React from 'react'
// import pic from '../../assets/Ellipse 89.svg'
// import lp from '../../assets/laptop.png'
// import profile from '../../assets/profilepic.jpg'
// import profile1 from '../../assets/usman.jpg'
// import bg from '../../assets/borderbg.svg'
import '../Navbar/laptopstyle.css'
// import laptop from '../../assets/Device - Macbook Pro.svg'
import second from '../../assets/image 55.svg'
// import headerbg from '../../assets/Headerbg.jpg'
// import mac from '../../assets/Device - Macbook Pro.svg'
// import image from '../../assets/laptop.png'
import '../../index.css'
// import nama from '../../assets/Talha.png'
import { NavLink } from 'react-router-dom'

export default function HeaderSection({ title, desc, bgGradient = false, profilepic, profiletitle, profiledesc, techimg, bgimage, rightsideimage, mobileimg, bookacall }) {
    const customGradientStyle = {
        backgroundImage: 'linear-gradient(to bottom, rgba(255, 192, 203, 1) 0%, rgba(255, 255, 255, 1) 100%)',
    };
    // Decide which style to apply based on bgGradient prop
    const backgroundStyle = bgGradient ? customGradientStyle : {
        backgroundImage: `url(${bgimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };
    return (
        <div className={`pt-40 -mt-28 `} style={backgroundStyle}
        // {/* style={{
        //     backgroundImage: `url(${bgimage})`,
        //     backgroundSize: 'cover', 
        //     backgroundPosition: 'center', 
        //     backgroundRepeat:"no-repeat"
        //     // height: '100vh',
        //   }} */}
        >
            <div className='lg:flex lg:justify-center lg:items-center '>
                <div className=' lg:w-1/2 lg:flex lg:flex-col lg:justify-center lg:items-center pt-32 lg:pt-0  lg:my-32 xl:my-[109px]'>
                    <h1 className={` ${bgGradient ? "text-[#2E3D62]" : "text-white"} xl:px-9 text-center  font-[700] text-[32px] xl:text-[40px]  leading-[44.2px]`}>{title}</h1>
                    <p className={` text-center  lg:w-[595px] font-[500] ${bgGradient ? "text-[#000000]" : "text-white"} pt-2.5 xl:pt-4 px-[14px] md:px-40 lg:px-14 text-[18px] leading-6 xl:text-[20px] xl:leading-[32.2px]`}>{desc}</p>
                    <div className="h-[300px] pt-2.5 xl:pt-4 -ml-8">
                        <div className='flex mb-5 py-16 pl-12 lg:p-[44px] space-x-3'>
                            <div className='flex justify-center gap-2 items-center max-w-[400px] mx-2 md:mx-auto'>
                                <div className=" lg:w-1/2 flex flex-col justify-center  lg:items-center">
                                    {profilepic && <img src={profilepic} alt="" className='lg:w-[136px] w-[92px] h-[92px] lg:h-[136px] rounded-[50%] object-cover' />
                                    }
                                    {/* <img src={nama} alt="" /> */}
                                    <p className="leading-5 xl:leading-[28px] text-[32px] pt-5 xl:text-[46px] text-[#DD2F29] font-rageItalic">{profiletitle}</p>
                                </div>
                                <div className='lg:w-1/2'>
                                    <p className={`font-[500] leading-[22px] xl:leading-[28.8px] lg:w-64 text-sm lg:text-[16px] ${bgGradient ? "text-[#000000]" : "text-white"}`}>{profiledesc}</p>
                                    {bookacall &&
                                        <NavLink to="/contact">
                                            <button className='bg-[#DD2F29] hover:bg-transparent hover:text-[#DD2F29] hover:border-[2px] hover:border-[#DD2F29] mt-4 capitalize rounded-lg w-[200px] lg:w-[160px] h-9 text-lg font-[500] text-white'>
                                                {bookacall}
                                            </button>
                                        </NavLink>}
                                    {/* <button  className='bg-[#DD2F29] hover:bg-transparent hover:text-[#DD2F29] hover:border-[2px] hover:border-[#DD2F29] mt-4 capitalize rounded-lg w-[200px] lg:w-[160px] h-9 text-lg font-[500] text-white'>
                                        Book a call
                                    </button> */}
                                </div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
                {
                    mobileimg && <div className=''>
                        <img src={mobileimg} alt="" className=" w-full" />
                    </div>
                }
                {/* {rightsideimage && <div className='lg:w-1/2 hidden lg:block  desktop-wrapper '>
                    <img src={second} alt="" className=" w-full" />
                </div>} */}
                {techimg && <div className='lg:w-1/2 hidden lg:block '>
                    <img src={techimg} alt="" className=" w-full" />
                </div>}
            </div>
        </div>
    )
}
