import React, { useState } from 'react'
import danish from '../../assets/images/danish.jpg'
import emailjs from 'emailjs-com';
import { UserOutlined } from '@ant-design/icons';
// import { MailOutlined } from '@ant-design/icons';
import img4 from '../../assets/images/aliRaza.jpg'
import { MdOutlineMailOutline } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa"
import img5 from '../../assets/images/nouman.jpg'
import img6 from '../../assets/images/usman.jpg'
import img7 from '../../assets/images/faisal.jpg'
import trophy from '../../assets/SVG/trophy.png.svg'
import review from '../../assets/SVG/review.png.svg'
import expert from '../../assets/SVG/expert.png.svg'
import rating from '../../assets/SVG/rating.png.svg'
import './style.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Form, Input, } from 'antd';
// import { Option } from 'antd/es/mentions';
import PhoneNumber from '../phonenumber/Phonenumber';
const onFinish = (values) => {
    // console.log('Success:', values);
    emailjs.send('service_838jj4r', 'template_akjtjmb', values, 'Mp3jGpu5LWhIWr06Q')
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            toast.success("SuccesFully Sended")
            values = {}
            // Handle success response, maybe show a success message
        }, (err) => {
            // console.log('FAILED...', err);
            toast.error("Error Occur")
            // Handle errors, maybe show an error message
        });

    console.log('Form Data:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};
const ScheduleSession = () => {
    const [valid, setValid] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');

    // Function to handle =============
    const handleChange = (value, value1) => {
        if (value && value1) {
            // setPhoneNumber(value);
            var countryCode = value1.split(" ")[0];
            var code = countryCode.split("+")[1];
            var number = value.split(code)[1];
            setPhoneNumber({ countryCode: countryCode, mobile: number });
        }
        setValid(validatePhoneNumber(value));
    };
    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
        return phoneNumberPattern.test(phoneNumber);
    };


    const expertsData = [
        {
            id: "1",
            img: danish,
            title: "Danish",

        },
        {
            id: "2",
            img: img4,
            title: "Ali Raza",

        },
        {
            id: "3",
            img: img5,
            title: "Nouman",

        },
        {
            id: "4",
            img: img6,
            title: "Usman",

        },
        {
            id: "5",
            img: img7,
            title: "Faisal",

        },

    ]

    const iconsData = [
        {
            id: "1",
            pic: trophy,
            title: "40+",
            paragraph: "In house expert"

        },
        {
            id: "2",
            pic: review,
            title: "150+",
            paragraph: "5 Star Reviews"

        },
        {
            id: "3",
            pic: expert,
            title: "80+",
            paragraph: "Client Globaly"

        },
        {
            id: "4",
            pic: rating,
            title: "100%",
            paragraph: "Job Success Rate"

        },
    ]

    return (
        <>
            <div className="flex flex-col items-center gap-4 pt-20 px-10 md:px-0">
                <div className="">
                    <p className="text-center text-[#2E3D62] text-[40px] leading-10 font-bold"> Arrange a free <span className="text-[#DD2F29]"> expert consultation. </span> </p>
                    <p className="text-center text-[#554D63] text-[18px] font-medium pt-10"> Receive recommendations, tailor-made solutions, developer profiles, or project estimates. </p>
                </div>
            </div>
            <div className=" relative flex flex-col-reverse md:flex-row items-center md:items-start justify-center md:my-14 mb-12 ">
                {/* Form */}
                <div className='-mt-20  md:-mt-0  z-10 md:z-0 bg-[#E0E4EF] relative rounded-lg md:rounded-none'>
                    <Form
                        className="flex flex-col gap-5 md:gap-12 rounded-md pt-11 md:mb-0 relative pb-20 md:mt-0 md:py-36 lg:py-16 px-[18px] md:px-9 w-auto md:pr-20 "
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        // style={{
                        //     maxWidth: 600,
                        // }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'It is a required Feild!',
                                },
                            ]}
                        >
                            <Input prefix={<FaRegUserCircle className="site-form-item-icon w-6 h-6" />} placeholder="Full Name" className="focus:outline-none  h-14 py-4 shadow rounded-md w-[284px] lg:w-96 font-normal text-[15px] text-[#86838D]"
                            />
                        </Form.Item>
                        <Form.Item
                            name='email'
                            rules={[
                                {

                                    type: 'email',
                                },
                            ]}
                        >
                            <Input prefix={<MdOutlineMailOutline className="site-form-item-icon w-6 h-6" />} placeholder="Email" className="focus:outline-none  h-14 py-4 shadow rounded-md w-[284px] lg:w-96 font-normal text-[15px] text-[#86838D]" />
                        </Form.Item>
                        <Form.Item name="phoneNumber">
                            <div className="">
                                <div dir="ltr">
                                    <PhoneNumber
                                        onChange={(
                                            phone,
                                            country,
                                            e,
                                            formattedValue
                                        ) => handleChange(phone, formattedValue)}
                                        // onPhoneCodeChange={(phoneCode: any) => handlePhoneCodeChange(phoneCode)}
                                        className="focus:outline-none pl-12 h-14 p-4 shadow rounded-md w-[284px] lg:w-96 font-normal text-[15px] text-[#86838D]"
                                        country="pk"
                                    />
                                </div>

                                {!valid && (
                                    <p className="text-red-500 text-sm">
                                        {"Please enter a valid phone number"}
                                    </p>
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item
    name="message"
    // label="Intro"
    rules={[
        {
            required: true,
            message: 'Please input Intro',
        },
    ]}
>
    <Input.TextArea showCount maxLength={500} placeholder='Write a Message' className="focus:outline-none p-4 h-14 pl-28 shadow rounded-md w-[284px] lg:w-96 font-normal text-[15px] text-[#86838D]" />
</Form.Item>


                        <div className='flex justify-center items-center'>
                            <button type="submit" className="border border-[#DD2F29] bg-[#DD2F29] w-[252px] md:w-[316px] text-white py-4 rounded-full font-medium text-[16px] absolute -bottom-5 hover:bg-white hover:text-[#DD2F29] transition duration-300">
                                Submit
                            </button>
                        </div>
                        {/* </Form.Item> */}
                    </Form>

                </div>
                {/* Content */}
                <div prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" className=" bg-[#131313] pb-28  md:z-50 pt-14 md:pt-10 md:pb-14 lg:py-14 xl:max-w-xl flex flex-col rounded-lg md:-ml-10 px-5 lg:px-14 mt-16">
                    <p className="text-white font-semibold text-lg md:text-[24px] mt-6">Our team is available to assist with any questions you might have. </p>
                    <div className="flex flex-row items-center gap-1 md:gap-3 pt-10">
                        {expertsData.map((item) => (
                            <div key={item.id} className='flex flex-col justify-start items-center' >
                                <div className="transition duration-300 ease-in-out transform hover:-translate-y-1 ">
                                    <img src={item.img} alt="img" className='w-16 md:w-20 h-16 md:h-20  rounded-md object-cover'/>
                                </div>
                                <div>
                                    <p className="text-white text-[14px] pt-1 ml-5">{item.title}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="grid grid-cols-2 pt-12 gap-5 gap-y-10">
                        {iconsData.map((item, index) => (
                            <div className='flex items-center justify-center gap-1' key={item.id}>
                                <div className="">
                                    <img src={item.pic} alt="pic" />
                                </div>
                                <div className="flex flex-col items-start justify-center text-white">
                                    <h1 className=' text-2xl'>{item.title}</h1>
                                    <p className=' text-sm'>{item.paragraph}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

        </>
    )
}

export default ScheduleSession