import React from 'react';
import './style.css';

const HireCard = ({ data }) => {
    return (
        <div className='flex flex-col w-full h-full lg:w-[300px] lg:h-[300px] xl:w-[345px] xl:h-[333px] ' key={data.id}>
            <div className="flex relative">
                <div className="font-black text-9xl text-[#CB0B0B] mr-20 relative">
                    {data.id}
                    <div className="underline"></div>
                </div>
                <div className="flex items-center justify-center pt-12">
                    <img src={data.icon} alt="img" className='object-contain' />
                </div>
            </div>
            <div className="pl-16 sm:pl-20 sm:pr-9">
                <h1 className='font-medium text-base lg:font-[600] text-[#234091] pt-6'>{data.heading}</h1>
                <p className='font-medium text-base pt-5 leading-7 '>{data.desc}</p>
            </div>
        </div>
    );
};

export default HireCard;
