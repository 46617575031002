import React from 'react'
import logo from '../../assets/mobilesectionlogo.svg'
import img from '../../assets/mobilesection1.svg'
export default function MobileSection1() {
  return (
    <div>
      <div className=' flex flex-col-reverse md:flex md:flex-row'>
        <div className='flex justify-center md:w-[50%] items-center'>
            <div className='flex flex-col justify-center text-clip w-[75%] pb-3'>
            <img src={logo} alt="" className=' w-16 h-16 xl:w-24 xl:h-24 rounded-xl object-contain' />
            <h1 className='text-[40px] md:text-[30px] font-[700] leading-[40px]  lg:leading-6 py-4 md:py-2 lg:py-4'>KIPS VIRTUAL</h1>
            <p className="text-[16px] font-[450] xl:pt-3 xl:pb-5 ">Experience tailored learning with adaptive quizzes, comprehensive resources, expert support, and progress tracking, all on a flexible, user-friendly online platform.</p>
            <p className="text-[16px] font-[450] xl:pt-3 xl:pb-5 block md:hidden lg:block  ">Unlock personalized learning with adaptive quizzes, unlimited practice materials, instant feedback, and access to a vast library of videos and e-Books.</p>
            {/* text: ["", "Experience tailored learning with adaptive quizzes, comprehensive resources, expert support, and progress tracking, all on a flexible, user-friendly online platform."], */}

            </div>
        </div>
        
        <div className='md:w-[50%] md:flex md:justify-center items-center'>
            <img src={img} alt="" />
        </div>
      </div>
    </div>
  )
}
