import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const accordionData = [
    {
        id: 1,
        question: "What are the advantages of React?",
        answer: "Answer to question 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
    {
        id: 2,
        question: "What can I build with React.js?",
        answer: "Answer to question 2. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    {
        id: 3,
        question: "What are hiring engagement options available at TechAvidus?",
        answer: "Answer to question 3. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: 4,
        question: "What are the advantages of React?",
        answer: "Answer to question 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
    {
        id: 5,
        question: "What can I build with React.js?",
        answer: "Answer to question 2. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    {
        id: 6,
        question: "What are hiring engagement options available at TechAvidus?",
        answer: "Answer to question 3. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: 7,
        question: "What are the advantages of React?",
        answer: "Answer to question 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
    {
        id: 8,
        question: "What can I build with React.js?",
        answer: "Answer to question 2. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    {
        id: 9,
        question: "What are hiring engagement options available at TechAvidus?",
        answer: "Answer to question 3. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: 10,
        question: "What are the advantages of React?",
        answer: "Answer to question 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
];

const FAQTechnology = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className='my-10'>
            <div className='px-5 lg:px-44'>
                <h1 className='text-[#DD2F29] font-bold text-[16px] md:text-[30px] lg:text-[40px] space-x-1 text-center'><span className='text-[#2E3D62] font-bold text-[16px] md:text-[30px] lg:text-[40px] space-x-1 text-center'>FAQs </span> for React Development Services</h1>
                <div className="accordion-container py-14">
                    {accordionData.map((item, index) => (
                        <AccordionItem
                            key={item.id}
                            question={item.question}
                            answer={item.answer}
                            isActive={activeIndex === index}
                            toggleAccordion={() => toggleAccordion(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const AccordionItem = ({ question, answer, isActive, toggleAccordion }) => {
    return (
        <div className="border overflow-hidden mb-2">
            <button
                className={`w-full text-left py-3 px-4 bg-[#444444] text-white focus:outline-none ${isActive ? 'bg-purple-700' : ''
                    }`}
                onClick={toggleAccordion}
            >
                <span className="flex items-center justify-between">
                    <span>{question}</span>
                    <span className="icon transition-all duration-300">{isActive ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
                </span>
            </button>
            <div
                className={`transition-all duration-300 ${isActive ? 'max-h-96' : 'max-h-0'
                    } overflow-hidden`}
            >
                <p className="px-4 py-2 bg-gray-100">{answer}</p>
            </div>
        </div>
    );
};

export default FAQTechnology;
