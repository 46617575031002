import React from 'react'
import growImg from '../../assets/reactjs-overview.png.svg'
export default function GrowTechnology() {
    const growdata = [
        {
            id: 1,
            img: growImg,
            title: "Grow your business with top rated React.JS development services",
            text: ["Development of digital product is a complex process that requires answering the user's needs, applying right technological solutions andcreating a secure application that provides an outstanding user experience.Choosing the expert front end developers and applying the right react frontend development services will have remarkable impact on your app success."],
            btnText: "LET'S WORK TOGETHER",
        },
    ]
    return (
        <>
            {growdata.map((items) => (
                <div className='mt-12'>
                    <div className='flex justify-center items-center text-center px-40'>
                        <p className='text-[#2E3D62] text-[40px] font-bold'>{items.title}</p>
                    </div>
                    <div className='flex w-full items-center '>
                        <div className='w-1/2 h-[288px] pl-44'>
                            <img src={items.img} alt='growTechImg' className='' />
                        </div>
                        <div className='w-1/2 space-y-3 pr-12'>
                            <p className='text-[16px] text-[#554D63] font-medium'>{items.text}</p>
                            <button className='bg-[#DD2F29] rounded-full w-[240px] h-[43px] text-[#FFFFFF] font-medium uppercase'>{items.btnText}</button>
                        </div>
                    </div>
                </div>
            ))}
        </>

    )
}
