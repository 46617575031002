import React from 'react'
import Heading from './Heading'
import S3bg from '../../assets/SVG/bgblack.svg'
import TechStack from './TechStack'

export default function OurTechnologies() {


  return (
    <div className='' style={{
      backgroundImage: `url(${S3bg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: "no-repeat",
      // height: '100%',
    }}>
      <Heading />
      <TechStack />
    </div>
  );
}
