import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import AngularImg from '../../assets/technologeis/angular.svg'
import ReactImg from '../../assets/technologeis/Reactjs.svg'
import FlutterImg from '../../assets/technologeis/flutter.svg'
import AndroidImg from '../../assets/technologeis/image 37.svg'
import RnativeImg from '../../assets/technologeis/Reactnative.svg'
import nodejs from '../../assets/technologeis/nodejs.svg'
import nextjs from '../../assets/technologeis/nextjs.svg'
import python from '../../assets/technologeis/pyhton.svg'

export default function TechStack() {
    const sliderSettings = {
        dots: false,
        infinite: true,
        centerMode: true, // Enable centered view
        centerPadding: '60px', // Adjust the padding to ensure items are centered
        speed: 500,
        slidesToShow: 5, // Adjust based on your design
        slidesToScroll: 1,
        autoplay: true,
        speed:7000,
        autoplaySpeed: 100,
        cssEase: "linear",
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };;
    const tech = [
        {
            id: "1",
            img: AngularImg,

        },
        {
            id: "2",
            img: ReactImg,
        },
        {
            id: "3",
            img: FlutterImg,
        },
        {
            id: "4",
            img: AndroidImg,
        },
        {
            id: "5",
            img: RnativeImg,
        },
    ]

    return (
        <div className="overflow-hidden pb-32 pt-10">
            {/* <div className='flex items-center justify-around  gap-16 animate-loop-scroll '> */}
                {/* {tech.map((items) => ( */}
                     <Slider {...sliderSettings}>
                        <div><img src={AngularImg} alt="" /></div>
                        <div><img src={ReactImg} alt="" /></div>
                        <div><img src={FlutterImg} alt="" /></div>
                        {/* <div><img src={AndroidImg} alt="" /></div> */}
                        <div><img src={RnativeImg} alt="" /></div>
                        {/* <div><img src={nextjs} alt="" /></div> */}
                        {/* <div><img src={nodejs} alt="" /></div> */}
                        {/* <div><img src={python} alt="" /></div> */}
                    {/* <div>
                        <img src={items.img} alt='tech' />
                    </div> */}
                    </Slider>
                {/* ))} */}
            {/* </div> */}
        </div>

    )
}
