import React from 'react'
import TechnologyCard from '../TechnologyCard/TechnologyCard'
import node from '../../assets/SVG/nodes.png.svg'

const PrefferedTechnology = () => {
    return (
        <div className='py-20 bg-[#F0F6FF] w-full my-10'>
            <div>
                <h1 className='text-[#DD2F29] font-bold text-[16px] md:text-[30px] lg:text-[40px] space-x-1 text-center'>Most preferred</h1>
                <h2 className='text-[#2E3D62] font-bold text-[16px] md:text-[30px] lg:text-[40px] space-x-1 text-center'>react JS combination we cater.</h2>
                <p className='text-center px-5 xl:px-36 py-4'>We offer end to end full stack ReactJS development expertise. Our React.JS developers are skilled at combining a possible number of front-end and back-end technologies to build scalable web apps.</p>
            </div>
            <div className="flex items-center justify-center flex-wrap gap-6">
                <TechnologyCard red={true} icon={node} title="React+ Node" text="JavaScript is veteran full stack development language providing scalability. Hire React Node developer from us to leverage the advantages of this powerful combination." />
                <TechnologyCard red={true} icon={node} title="React+ Node" text="JavaScript is veteran full stack development language providing scalability. Hire React Node developer from us to leverage the advantages of this powerful combination." />
                <TechnologyCard red={true} icon={node} title="React+ Node" text="JavaScript is veteran full stack development language providing scalability. Hire React Node developer from us to leverage the advantages of this powerful combination." />
                <TechnologyCard red={true} icon={node} title="React+ Node" text="JavaScript is veteran full stack development language providing scalability. Hire React Node developer from us to leverage the advantages of this powerful combination." />
                <TechnologyCard red={true} icon={node} title="React+ Node" text="JavaScript is veteran full stack development language providing scalability. Hire React Node developer from us to leverage the advantages of this powerful combination." />
                <TechnologyCard red={true} icon={node} title="React+ Node" text="JavaScript is veteran full stack development language providing scalability. Hire React Node developer from us to leverage the advantages of this powerful combination." />
            </div>
            <div className="text-center pt-10">
                <p>Are you interested to know how we can assist you with React.Js Development?</p>
                <button className="mt-4 border border-[#DD2F29] bg-[#DD2F29] w-[252px] md:w-[316px] text-white py-4 rounded-full font-medium text-[16px] hover:bg-white hover:text-[#DD2F29] transition duration-300 uppercase">
                    Let's Connect
                </button>
            </div>
        </div>
    )
}

export default PrefferedTechnology