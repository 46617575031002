import React from 'react'
import TechnologyCard from '../TechnologyCard/TechnologyCard'
import meeting from '../../assets/SVG/meeting.png.svg'
const WhyForServices = () => {
    return (
        <div className='my-10'>
            <div className='lg:px-44'>
                <h1 className='text-[#DD2F29] font-bold text-[16px] md:text-[30px] lg:text-[40px] space-x-1 text-center'>Why Organizations Choose TechAvidus</h1>
                <h2 className='text-[#2E3D62] font-bold text-[16px] md:text-[30px] lg:text-[40px] space-x-1 text-center'>for Web Development Services</h2>
                <p className='text-center px-5 xl:px-36 py-4'>Every custom web application developed by TechAvidus undergoes comprehensive research and strategy as per the client's custom requirements.</p>
            </div>
            <div className="flex items-center justify-center flex-wrap gap-6 pt-7">
                <TechnologyCard red={true} icon={meeting} title="Expert Vetted Resources" text="Our engineering experts are selected (never through generalized recruiters or HR reps) based on 6+ hours of tests and face-to- face or video interviews" />
                <TechnologyCard red={true} icon={meeting} title="Expert Vetted Resources" text="Our engineering experts are selected (never through generalized recruiters or HR reps) based on 6+ hours of tests and face-to- face or video interviews" />
                <TechnologyCard red={true} icon={meeting} title="Expert Vetted Resources" text="Our engineering experts are selected (never through generalized recruiters or HR reps) based on 6+ hours of tests and face-to- face or video interviews" />
                <TechnologyCard red={true} icon={meeting} title="Expert Vetted Resources" text="Our engineering experts are selected (never through generalized recruiters or HR reps) based on 6+ hours of tests and face-to- face or video interviews" />
                <TechnologyCard red={true} icon={meeting} title="Expert Vetted Resources" text="Our engineering experts are selected (never through generalized recruiters or HR reps) based on 6+ hours of tests and face-to- face or video interviews" />
                <TechnologyCard red={true} icon={meeting} title="Expert Vetted Resources" text="Our engineering experts are selected (never through generalized recruiters or HR reps) based on 6+ hours of tests and face-to- face or video interviews" />
                <TechnologyCard red={true} icon={meeting} title="Expert Vetted Resources" text="Our engineering experts are selected (never through generalized recruiters or HR reps) based on 6+ hours of tests and face-to- face or video interviews" />
                <TechnologyCard red={true} icon={meeting} title="Expert Vetted Resources" text="Our engineering experts are selected (never through generalized recruiters or HR reps) based on 6+ hours of tests and face-to- face or video interviews" />
                <TechnologyCard red={true} icon={meeting} title="Expert Vetted Resources" text="Our engineering experts are selected (never through generalized recruiters or HR reps) based on 6+ hours of tests and face-to- face or video interviews" />
            </div>
        </div>
    )
}

export default WhyForServices