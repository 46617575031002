import React from 'react'
import './techCard.css'

const TechnologyCard = ({ icon, title, text, red }) => {
    return (
        <div id='techcard' className='flipimg cursor-pointer hover:scale-105 hover:shadow-lg duration-500 rounded shadow flex flex-col sm:max-w-[404px] sm:max-h-[360px] items-start justify-center bg-white px-6 border'>
            <div className="py-6 ">
                <img src={icon} alt="image" className='object-contain' />
            </div>
            <h1 className={`font-medium ${red ? 'text-[#DD2F29]' : ''} text-[17px]  py-4`}>{title}</h1>
            <p className='font-light text-base leading-7 pb-[71px]'>{text}</p>
        </div>
    )
}

export default TechnologyCard