import React from 'react'
import Circle from './Circle'

export default function ServicesWeOffer() {

    return (
        <div className='flex flex-col px-4'>
            <div className='pt-3'>
                <p className='text-[#2E3D62] font-bold text-[16px] md:text-[30px] lg:text-[40px] space-x-1 text-center'>
                    React.JS App Development Services<span className='text-[#DD2F29] pl-1'>we offer </span>
                </p>
            </div>
            <Circle />
            <div className='flex justify-center p-4'>
                <p className='text-[#554D63] text-[16px] font-medium'>
                    Are you interested to know, how we can assist you in your mobile app development requirements?
                </p>
            </div>
        </div>
    )
}

