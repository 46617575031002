import React from 'react'
import '../Partners/partner.css'
export default function Heading() {
  return (
    <div className=' pt-32 md:pt-36'>
      <div className='flex justify-center items-center '>
        <h1 className='text-[#FFFFFF] font-bold text-[30px] technology  leading-[44px] md:leading-[52px] md:text-[40px]'> OUR <span className='text-[#DD2F29]'>TECHNOLOGIES</span></h1>
      </div>
      <div className='flex justify-center items-center px-5 md:px-32 xl:px-96'>
        <p className='text-[#FFFFFF] font-medium text-center text-[18px]  md:text-[22px]'>We're adept at harnessing the latest technologies to drive innovation and deliver exceptional solutions tailored to your needs</p>
      </div>
    </div>
  )
}
