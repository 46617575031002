import React from 'react'
import logo from '../../assets/logo.svg'
import { NavLink } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa6";
import { MdLocationPin } from "react-icons/md";
import { IoIosMailUnread } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";

// import { MailOutlined } from '@ant-design/icons';
import footerbg from '../../assets/footerbg.png'
// import flagimg from '../../assets/SVG/flagimg.svg'
// import facebook from '../../assets/facebook.png'
// import instgram from '../../assets/instagram.png'
// import linkdin from '../../assets/linkedin.png'
// import pintrest from '../../assets/pinterest.png'
// import twitter from '../../assets/twitter.png'
// import Pakistan from '../../assets/flag.png'
// import england from '../../assets/england.png'
import { IoIosMail } from "react-icons/io";
import '../Navbar/navbar.css'
import emailjs from 'emailjs-com';
import '../Footer/footerstyle.css'
import { Form } from 'antd';
import { toast } from 'react-toastify';
const onFinish = (values) => {
  // console.log('Success:', values);
  // toast.success("Thanks For Joining US")
  emailjs.send('service_838jj4r', 'template_angyj0p', values, 'Mp3jGpu5LWhIWr06Q')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      toast.success("Thanks For Joining US")
      values = {}
      // Handle success response, maybe show a success message
    }, (err) => {
      // console.log('FAILED...', err);
      toast.error("Error Occur")
      // Handle errors, maybe show an error message
    });
};
const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};


export default function Footer() {
  const handlepage = () => {
    window.scroll(0, 0)
  }
  // const contactaccounts = [
  //   {
  //     id: "1",
  //     img: facebook,
  //     link: "https://www.facebook.com/facebookcom"
  //   },
  //   {
  //     id: "2",
  //     img: linkdin,
  //     link: "https://www.linkedin.com/"
  //   },
  //   {
  //     id: "3",
  //     img: pintrest,
  //     link: "https://www.pinterest.com/"
  //   },
  //   {
  //     id: "4",
  //     img: twitter,
  //     link: "https://twitter.com/"
  //   },
  //   {
  //     id: "5",
  //     img: instgram,
  //     link: "https://www.instagram.com/?hl=en"
  //   },
  // ]
  return (
    <div className='px-2 bg-black  lg:px-11 xl:px-16'>
      <div className='bg-black py-8 lg:flex'>
        <div className='lg:w-[30%] xl:w-[35%]'>
          {/* Logo */}
          <div className='flex justify-center items-center cursor-pointer pb-4 gap-3    '>
            <NavLink to="/"
            > <img src={logo} alt="logo" className='h-[47px] logo-spin lg:h-[57px]   w-auto' /></NavLink>

            <NavLink to="/">
              <div>
                <h1 className='text-[#FF0000] text-[40px] lg:text-[49px] leading-none font-extrabold'>HNL</h1>
                <p className={` text-white font-[700] text-[12px] lg:text-[18px] capitalize leading-none `}>Tech-Solutions</p>
              </div>
            </NavLink>

          </div>
          {/* Text */}
          <div className='flex justify-center items-center py-5'>
            <p className='font-[500] text-center px-14 lg:px-0 w-80 text-[16px] fottertext leading-[22px] text-[#FFFFFF]'>
              Innovative Software Solutions Tailored to Your Business Needs
            </p>
          </div>
          {/* Link Footer */}
          <div className="flex justify-center items-center pb-5 gapfooter gap-5">
            <a href='https://www.facebook.com/people/HNL-Tech-Solutions/61556940230197/' target="_blank" className='border-[1px] border-[#B90505] w-[50px] h-[40px] hover:bg-[#B90505] rounded-lg flex justify-center items-center'>
              <FaFacebookF className='text-white w-5 h-5' />
            </a>
            <a href='https://www.instagram.com/?hl=en' target="_blank" className='border-[1px] border-[#B90505] w-[50px] h-[40px] hover:bg-[#B90505] rounded-lg flex justify-center items-center'>
              <BsInstagram className='text-white w-5 h-5' />
            </a>
            <a href='https://twitter.com/' target="_blank" className='border-[1px] border-[#B90505] w-[50px] h-[40px] hover:bg-[#B90505] rounded-lg flex justify-center items-center'>
              <BsTwitterX className='text-white w-5 h-5' />
            </a>
            <a href='https://www.linkedin.com/company/hnl-tech-solutions/about/' target="_blank" className='border-[1px] border-[#B90505] w-[50px] h-[40px] hover:bg-[#B90505] rounded-lg flex justify-center items-center'>
              <FaLinkedinIn className='text-white w-5 h-5' />
            </a>
          </div>
        </div>
        <div className='lg:flex lg:w-[70%] lg:gap-5  lg:pt-8'>
          {/* Contact US */}
          <div className='py-2 lg:w-1/3'>
            <div className='py-4'>
              <h1 className="text-[25px] leading-[30px] tracking-normal font-[600] text-center lg:text-start text-[#DD2F29]">Contact</h1>
            </div>
            <div className='space-y-5'>
              <div className='flex justify-center lg:justify-start lg:items-start  items-center gap-4'>
                <div>
                  <MdLocationPin className='text-[#B90505] w-5 h-5 ' />
                </div>
                <p className='text-[16px] fottertext font-[500] leading-5 text-[#FFFFFF]'>Lower Ground Floor, Commercial Arcade, Divine Gardens, New Airport Road Lahore, Pakistan</p>
              </div>
              <div className='flex justify-center  lg:justify-start  items-center lg:items-start gap-4'>
                <div>
                  <IoIosMail className='text-[#B90505] w-5 h-5 ' />

                </div>
                <a target="_blank" href="mailto:info@hnltechsolution.com" className='xl:text-[16px] text-sm fottertext font-[500] leading-5 text-[#FFFFFF]'>info@hnltechsolution.com</a>
              </div>
              <div className='flex justify-center lg:justify-start  items-center lg:items-start gap-4'>
                <div>
                  <FaPhoneAlt className='text-[#B90505] w-5 h-5 ' />

                </div>
                <a href="tel:+9242111000845" className='text-[16px] fottertext font-[500] leading-5 text-[#FFFFFF]'>(+92-42) 111-000-845</a>
              </div>
            </div>
          </div>

          <div className='py-3 lg:w-[28%]'>
            <div className='py-3'>
              <h1 className="text-[25px] leading-[30px] tracking-normal lg:text-start font-[600] text-center text-[#DD2F29]">
                Quick Links
              </h1>
            </div>
            <div className='flex justify-center items-center lg:items-start lg:justify-start pt-1.5'>
              <ul className='text-center lg:text-start space-y-4'>
                <li>
                  <NavLink to="/services" onClick={handlepage} className='text-[16px] fottertext font-[500] leading-5 hover:text-[#B90505] text-[#FFFFFF]'>
                    Services
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ourprojects" onClick={handlepage} className='text-[16px] fottertext font-[500] leading-5 hover:text-[#B90505] text-[#FFFFFF]'>
                    Our Projects

                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" onClick={handlepage} className='text-[16px] fottertext font-[500] leading-5 hover:text-[#B90505] text-[#FFFFFF]'>
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" onClick={handlepage} className='text-[16px] fottertext font-[500] leading-5 hover:text-[#B90505] text-[#FFFFFF]'>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className='hidden lg:block py-2 lg:w-[45%]'>
            <div className='py-3'>
              <h1 className="text-[35px] xl:text-[40px] leading-[50px] tracking-normal lg:text-start font-[650] text-center text-[#DD2F29]">
                Join US
              </h1>
            </div>
            <div className='w-full space-y-5'>
              <Form
                className='w-full'
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                // style={{
                //   maxWidth: 600,
                // }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  // className='w-full'
                  name='email'
                  rules={[
                    {

                      type: 'email',
                      required: true,
                      message: 'Please Enter Correct Email',
                    },
                  ]}
                >
                  <input
                    required
                    type="email"
                    placeholder="Email Address"
                    className="h-[48px] pl-5 w-72 xl:w-96 text-[#FFFFFF] bg-[#FFFFFF] bg-opacity-10 rounded-[50px]  border-red-500 border-2 outline-none"
                  />
                </Form.Item>

                <button className='text-[16px] font-[600] leading-[23px] w-full text-[#FFFFFF] h-[50px] rounded-[100px] bg-[#B90505] hover:bg-transparent hover:border-[1px] hover:text-[#B90505] hover:border-[#B90505]'>Submit</button>
              </Form>
              {/* <input
                required
                type="email"
                placeholder="Email Address"
                className="h-[48px] pl-5 w-full text-[#FFFFFF] bg-[#FFFFFF] bg-opacity-10 rounded-[50px] border border-transparent focus:border-red-500 focus:border-2 outline-none"
              />
              <button className='text-[16px] font-[600] leading-[23px] w-full text-[#FFFFFF] h-[50px] rounded-[100px] bg-[#B90505] hover:bg-transparent hover:border-[1px] hover:text-[#B90505] hover:border-[#B90505]'>Submit</button> */}
            </div>
          </div>
        </div>
      </div>
      <hr className='bg-[#CCCCCC] h-[1px] lg:h-0.5' />
      <div className='bg-black h-5 flex justify-center py-9 items-center'
        style={{
          backgroundImage: `url(${footerbg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: "no-repeat"
          // height: '100vh',
        }}
      >
        <div className=''>
          <p className='text-[16px] text-center fottertext font-[500] leading-5 text-[#FFFFFF]'>All Rights Reserved. Copyright © 2024 | HNL Tech-Solutions </p>
        </div>
      </div>
    </div>
  )
}
