import React from 'react'
import Title from './Title'
import Techcard from './Techcard'

function WhyHNLTech() {
  return (
    <div className='mb-9'>
      <Title />
      <Techcard />
    </div>
  )
}

export default WhyHNLTech