import React from 'react'
import { FcGoogle } from "react-icons/fc";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
// import ReveiwCircle from '../ReveiwCircle';

export default function ReveiwCircle() {
  return (
    <div>
      <div className='border flex justify-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg items-center w-28 h-28 md:w-[100px] md:h-[100px] lg:w-[110px] lg:h-[110px] xl:w-[135px] xl:h-[135px] rounded-[50%] bg-[#EDEBEB] bg-opacity-30 shadow-md'>
                    <div className='xl:w-[115px] xl:h-[115px] lg:w-[100px] lg:h-[100px] w-24 h-24 md:w-20 md:h-20 rounded-[50%] flex flex-col justify-center items-center border-dashed border-2 border-[#2d4174] '>
                        <div>
                            <FcGoogle className="xl:w-[60px] w-10 md:w-8 md:h-8 h-10 lg:w-9 lg:h-9 xl:h-[60px] rounded-[50%]" />
                        </div>
                        <div className='flex'>
                            <FaStar className='rounded-[50%] text-yellow-400 md:w-3 md:h-3 lg:w-full lg:h-full' />
                            <FaStar className='rounded-[50%] text-yellow-400 md:w-3 md:h-3 lg:w-full lg:h-full' />
                            <FaStar className='rounded-[50%] text-yellow-400 md:w-3 md:h-3 lg:w-full lg:h-full' />
                            <FaStar className='rounded-[50%] text-yellow-400 md:w-3 md:h-3 lg:w-full lg:h-full' />
                            <FaStarHalfAlt className='rounded-[50%] text-yellow-400 md:w-3 md:h-3 lg:w-full lg:h-full' />
                        </div>
                    </div>
                </div>
    </div>
  )
}
