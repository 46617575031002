import React from 'react'
import sectionbg from '../../assets/bgredgradient.svg'
import mobile from '../../assets/mobilephone.svg'
import mobile1 from '../../assets/mobile2.svg'
import mobile2 from '../../assets/mobile3.svg'
import bgsection from '../../assets/curvebg.svg'
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/autoplay'
import "swiper/css/effect-coverflow";
// import "swiper/css/pagination";
// import '../Mobile/mobilestye.css';
import { Navigation, Pagination,EffectCoverflow, Scrollbar,Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
export default function Mobilesection() {
  // const swiperRef = useRef(null);

  // useEffect(() => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.update();
  //   }
  // }, []);
  return (
    <div>
      <div className="relative  pt-14 lg:flex justify-center items-start z-0 lg:pt-28  xl:pt-52 pb-14 gap-7 lg:gap-4 lg:pl-16 xl:pr-14"
        style={{
          backgroundImage: `url(${sectionbg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          //   height: '100vh',
        }}
      >
        <div><img src={bgsection} alt="" className='absolute top-0 left-0' /></div>

        {/* <div className='lg:w-[%] '> */}
         
          <Swiper 
          modules={[Navigation, Pagination, EffectCoverflow, Scrollbar, Autoplay]}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: true,
          }}
          // spaceBetween={50}
          effect={'coverflow'}
          coverflowEffect={{
            
            rotate: 45,
            scale:1,
            stretch: 0,
            depth: 125,
            modifier: 1,
            slideShadows: false,
          }}
          slidesPerView={3} 
          // spaceBetween={5}
          // Changed to 'auto' for flexibility with different screen sizes
          // You can experiment with a specific number or 'auto' depending on your design needs
          // pagination={{ clickable: true }}
          // Uncomment navigation if you want to use arrows for navigation
          // navigation
        >
          <SwiperSlide><img src={mobile} alt="Mobile" /></SwiperSlide>
          <SwiperSlide><img src={mobile1} alt="Mobile 1" /></SwiperSlide>
          <SwiperSlide><img src={mobile2} alt="Mobile 2" /></SwiperSlide>
          <SwiperSlide><img src={mobile} alt="Mobile again" /></SwiperSlide> {/* Repeated mobile for demonstration */}
        </Swiper>
          
        {/* </div> */}
        <div className='lg:w-[50%] flex flex-col justify-start items-start px-5 md:px-14 lg:px-0'>
          <div className='lg:w-[78%] w-full'>
            <h1 className='font-[700] text-[35px] xl:text-[40px] leading-[45px] text-[#000000]'>Mobile App
              <span className=' pl-4 font-[400] text-[40px] leading-[45px] text-[#000000]'>
                for Telecom Service Providers</span></h1>
            <p className="text-[16px] pt-5 font-[500] text-[#000000] leading-7">
            Since 2004, HNL has grown into Pakistan's top Managed Services and power solutions provider for telecoms, starting as Hightech Electric Engineering.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}