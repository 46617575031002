import React from 'react'
import MyButton from '../MyButton/MyButton'
import HireCard from '../HireCard/HireCard'
import image1 from '../../assets/images/step2.webp.svg'
import image2 from '../../assets/images/step1.webp.svg'
import image3 from '../../assets/images/step3.webp.svg'
import { NavLink } from 'react-router-dom'

const RemoteDevelopers = () => {
    const hireData = [
        {
            id: "1",
            icon: image1,
            heading: "Share your requirements.",
            desc: "We'll assess your needs and provide a selection of pre-screened developers in just a few days.",
        },
        {
            id: "2",
            icon: image2,
            heading: "Arrange an interview.",
            desc: "Engage with and select from pre-approved developers you find suitable.",
        },
        {
            id: "3",
            icon: image3,
            heading: "Launch a trial.",
            desc: "Employ a developer for a 2-week risk-free trial and commence your project development.",
        },
    ]
    return (
        <div className='bg-[#F5F8F7] py-11 pl-[8%] sm:px-[10%] lg:px-[17%]'>
            <MyButton heading="3 Steps to" span="Hire Remote Developers" />
            <div className="mt-12 flex flex-col lg:flex-row lg:items-center items-start justify-center gap-16 sm:gap-7 xl:gap-2">
                {hireData.map((item, index) => {
                    return (
                        <div className='' key={index}>
                            <HireCard data={item} />
                        </div>
                    );
                })}

            </div>
            <NavLink to='/contact'>             <p className='mt-10 xl:mt-0 pt-5 font-semibold text-base text-center cursor-pointer hover:underline underline-offset-2 text-[#234091]'>Recruit a Talented Developer Today! </p>
</NavLink>
        </div>
    )
}

export default RemoteDevelopers